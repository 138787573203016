import MenuIcon from "@mui/icons-material/Menu";
import ChatIcon from "@mui/icons-material/Chat"
import { IconButton, styled, ThemeProvider } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import deloitte_logo from "./deloitte_logo.png";
import NavigationDrawer from './features/drawer/NavigationDrawer';
import HistoryDrawer from './features/drawer/HistoryDrawer';
import { PdfViewer } from './features/PdfViewer';
import DocumentManagement from './pages/DocumentManagement';
import VectorSearchPage from "./pages/VectorSearchPage";
import EnhancedGoogleSearch from './pages/EnhancedGoogleSearch';
import SingleDocumentSearch from './pages/SingleDocumentSearch';
import customTheme from './theme/theme';
import Login from './pages/Login';
import Chatbot from './pages/Chatbot';
import ChatbotFormAssistant from "./pages/Chatbot_FormAssistant";
import FormFiller from "./pages/FormFiller";
import TokenValidationWrapper from "./auth/AuthWrapper";
import ChatSourcePage from "./pages/ChatSourcePage";
import { useLocation } from "react-router-dom";
import ErrorBanner from "./features/errorBanner/errorBanner";
import { demo_mode } from "./config/config";

function App() {
  const [loggedIn, setLoggedIn ] = React.useState(false);
  const [error, setError] = React.useState(null);

  const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      // color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 20,
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [openHistoryDrawer, setOpenHistoryDrawer] = React.useState(false);

  React.useEffect(() => {
    //on reload, get variable loggedIn current session
    setLoggedIn(JSON.parse(window.localStorage.getItem('loggedIn')));
      }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerHistoryOpen = () => {
    setOpenHistoryDrawer(true);
  };

  const handleDrawerHistoryClose = () => {
    setOpenHistoryDrawer(false);
  };

  const isChatRoute = useLocation().pathname === '/chat';

  return (
    <ThemeProvider theme={customTheme}>

      <div className="App">
          {error && <ErrorBanner message={error} errorSetter={setError} />}
        <div className="App-drawer" id="App-drawer">
          <NavigationDrawer open={open} handleDrawerClose={handleDrawerClose} />
        </div>
        <div className="App-main">
        {(open === false) && (
          <div className="App-drawer-button-closed" id="App-drawer-button">
            <CustomToolTip title="Open Sidebar" placement="right">
              <IconButton aria-label="Open Sidebar" color="primary" onClick={handleDrawerOpen} size="large">
                <MenuIcon></MenuIcon>
              </IconButton>
            </CustomToolTip>
          </div>
        )}
        {isChatRoute && (openHistoryDrawer === false) && (
          <div className="App-HistoryDrawer-button-closed" id="App-HistoryDrawer-button">
            <CustomToolTip title="Open History" placement="right">
              <IconButton aria-label="Open History" color="primary" onClick={handleDrawerHistoryOpen} size="large">
                <ChatIcon></ChatIcon>
              </IconButton>
            </CustomToolTip>
          </div>
        )}
          <div className="Deloitte-Logo">
            <img src={deloitte_logo} alt="This is the deloitte logo" style={{ height: '100px' }} />
          </div>
          <div className="App-main-content">
            <Routes>
            <Route
                path="/"
                element={
                    <Login loginSetter={setLoggedIn} />
                }
              ></Route>
            <Route
                path="/login"
                element={<Login loginSetter={setLoggedIn}/>}
              ></Route>
              <Route
                path="/documentSearch"
                element={
                  <TokenValidationWrapper>
                    <SingleDocumentSearch />
                  </TokenValidationWrapper>
              }
              >
              </Route>
              <Route
                path="/chatSource"
                element={
                  <TokenValidationWrapper>
                    <ChatSourcePage />
                  </TokenValidationWrapper>
              }
              >
              </Route>
              <Route
                path="/chat"
                element={
                  <TokenValidationWrapper>
                <Chatbot />
                </TokenValidationWrapper>
                }
              ></Route>
              {(demo_mode === "FULL_APP" || demo_mode === "GPS_DEMO") && <Route
                path="/chatAIChatAssistant"
                element={
                  <TokenValidationWrapper>
                <ChatbotFormAssistant />
                </TokenValidationWrapper>
                }
              ></Route>}
              {(demo_mode === "FULL_APP" || demo_mode === "GPS_DEMO") && <Route
                path="/formFiller"
                element={
                  <TokenValidationWrapper>
                <FormFiller devmode="true" />
                </TokenValidationWrapper>
                }
              ></Route>}
              <Route
                path="/internetSearch"
                element={
                  <TokenValidationWrapper>
                    <EnhancedGoogleSearch />
                  </TokenValidationWrapper>
              }
              ></Route>
              <Route
                path="/PDFViewer"
                element={
                  <TokenValidationWrapper>
                    <PdfViewer pdfUrl={"pdfUrl"} />
                  </TokenValidationWrapper>
              }
              ></Route>
              <Route
                path="/documentManagement"
                element={
                  <TokenValidationWrapper>
                    <DocumentManagement />
                  </TokenValidationWrapper>
              }
              ></Route>
              {(demo_mode === "FULL_APP" || demo_mode === "AML_ONLY") && <Route
                path="/vectorSearch"
                element={
                  <TokenValidationWrapper>
                    <VectorSearchPage />
                  </TokenValidationWrapper>
              }
              ></Route>}
            </Routes>
          </div>
        </div>
                 {isChatRoute && (
          <div className="App-HistoryDrawer" id="App-HistoryDrawer">
            <HistoryDrawer open={openHistoryDrawer} handleHistoryDrawerClose={handleDrawerHistoryClose} setError={setError} />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
